import * as React from "react"
import { Layout } from "../components/layout"
import { Helmet } from 'react-helmet'

const Title = "Ian Knowles";
const Description = "Hi I'm Ian Knowles, Musical Artist and Professional Hacker Geek!";

// markup
const IndexPage = () => {
  return (
    <Layout>
      <Helmet defer={false}>
        <title>{Title}</title>
        <meta name="description" content={Description} />
      </Helmet>
      <section className="hero">
        <div className="container text-center">
          <h1 data-ovo-click="Ian Knowles">IAN KNOWLES</h1>
          <p>Hi I'm Ian Knowles, Musical Artist and Professional Hacker Geek!</p>
          <p className="icons">
            <a href="https://www.facebook.com/IanKnowlesStudio"><i className="icon-facebook">&#xe801;</i></a>
            <a href="https://soundcloud.com/ianknowles"><i className="icon-soundcloud">&#xe800;</i></a>
            <a href="https://www.youtube.com/channel/UCZ3T158__x5H-OBBwAP4SfA"><i className="icon-youtube-1">&#xe803;</i></a>
          </p>
        </div>
      </section>
      <section className="dark">
        <div className="container">
          <div className="grid-col2">
            <div>
              <h2>Blackbox</h2>
              <p>Blackbox is an all-in-one mastering system that combines a simple interface with powerful track mastering capabilities</p>
              <a href="/blackbox" className="btn-primary">read more</a>
            </div>
            <div>
              <a href="/blackbox">
                  <img src="/content/blackbox/II/BBXII2.png" className="img-cover full-width" alt="Blackbox II - Audio Mastering Software" />
              </a>
            </div>
          </div>
        </div>
        <div className="top-spacer"></div>
        <div className="container">
          <div className="grid-col2">
            <div>
              <h2>Summit Limiter VST / AU</h2>
              <p>Summit is a versitle mastering limiter plugin VST2 / VST3 and AU. Controlled loudness and clarity for your productions</p>
              <a href="/summit" className="btn-primary">read more</a>
            </div>
            <div>
              <a href="/summit">
                <img src="/content/summit/IanKnowles-Summit-Limiter.png" className="img-cover full-width" alt="Summit Limiter Plugin VST / AU by Ian Knowles" />
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="light">
        <div className="container">
          <h3>About / Bio</h3>
          <div className="grid-col2">
            <div>
              <img src="/images/IanKnowles.jpg" alt="Ian Knowles" className="img100" />
            </div>
            <div>
              <p>
                So I have introduced myself as a Musical Artist and Professional Hacker.
                I have been making music since high school and I am a full time programmer / producer and performer.
              </p>
              <p>Most of my work involves creating things others can't and I have made a career from doing this. I have production credits on 5 top 20's and have been fortunate enough to perform across 3 different continents.</p>
              <p>My productions and remixes have found homes on labels like</p>
              <ul>
                <li>Sony / BMG</li>
                <li>Armada Music</li>
                <li>Spinnin Records</li>
                <li>Ministry of Sound</li>
                <li>Nukleuz</li>
                <li>A&amp;S Records</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

/*
<script type="application/ld+json">
  {
    "@@context": "http://schema.org/",
    "@@type": "Person",
    "name": "Ian Knowles",
    "url": "https://ianknowles.com",
    "image": "https://ianknowles.com/images/ianknowles.jpg",
    "sameAs": [
    "https://www.facebook.com/IanKnowlesStudio",
    "https://www.youtube.com/channel/UCZ3T158__x5H-OBBwAP4SfA",
    "https://soundcloud.com/IanKnowles"
    ],
    "jobTitle": "Music Producer"
  }
</script>
*/
